/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Container, Flex, Heading, Themed, jsx } from "theme-ui"
import { graphql, PageProps } from "gatsby"
import { useAtom } from "jotai"
import { useReducer, useMemo, useEffect } from "react"

import type { CategoryParentQuery } from "../../../graphql-types"
import { displayListAtom } from "../../atoms"

import AguilaButton from "components/AguilaButton"
import CategoryHero from "components/CategoryHero"
import Layout from "components/Layout"
import ProductCard from "components/ProductCard"

const filterInitialState = {
  allFilters: [],
  calibers: [],
  bullet_type: [],
  application: [],
  weight: [],
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "addFilter":
      if (state[action.filterName]) {
        state[action.filterName].push(action.value)
        state.allFilters.push({
          value: action.value,
          filterName: action.filterName,
        })
        return {
          ...state,
        }
      } else {
        state.allFilters.push({
          value: action.value,
          filterName: action.filterName,
        })
        return {
          ...state,
          [action.filterName]: [action.value],
        }
      }

    case "removeFilter":
      const allFilter = state.allFilters
      const allIndex = allFilter.findIndex((x: any) => x.value === action.value)
      if (allIndex > -1) {
        allFilter.splice(allIndex, 1)
      }

      const filter = state[action.filterName]
      const index = filter.indexOf(action.value)
      if (index > -1) {
        filter.splice(index, 1)
      }
      return { ...state }
    case "clearFilter":
      return {
        allFilters: [],
        calibers: [],
        bullet_type: [],
        application: [],
        weight: [],
      }
    default:
      throw new Error()
  }
}

interface CategoryParentProps extends PageProps {
  data: CategoryParentQuery
}

const CategoryParent = ({ data, ...props }: CategoryParentProps) => {
  const [state, dispatch] = useReducer(reducer, filterInitialState)
  const [displayList, setDisplayList] = useAtom(displayListAtom)

  const handleDisplayChange = () => setDisplayList(displayList => !displayList)

  useEffect(() => {
    let timeout: NodeJS.Timer
    const handleResize = () => {
      window.matchMedia("(max-width: 768px)") && setDisplayList(false)
    }

    const debouncedHandleResize = () => {
      clearTimeout(timeout)
      timeout = setTimeout(handleResize, 100)
    }

    window.addEventListener("resize", debouncedHandleResize)

    return () => window.removeEventListener("resize", debouncedHandleResize)
  }, [])

  const createFilterArr = (data: any, id: string) => {
    return data.map((item: string, index: number) => ({
      name: item,
      id: `${id}-${index}`,
    }))
  }

  const NewFilterData = [
    {
      name: "caliber/gauge",
      id: "calibers",
      filterOptions: createFilterArr(data.UniqueCalibers?.distinct, "calibers"),
    },
    {
      name: "bullet types",
      id: "bullet_type",
      filterOptions: createFilterArr(
        data.UniqueBulletTypes?.distinct,
        "bullet_type"
      ),
    },
    {
      name: "application",
      id: "application",
      filterOptions: createFilterArr(
        data.UniqueApplications?.distinct,
        "application"
      ),
    },
    {
      name: "Weight",
      id: "weight",
      filterOptions: createFilterArr(data.UniqueWeights?.distinct, "weight"),
    },
  ]

  const filteredProducts = useMemo(() => {
    if (state.allFilters.length === 0) return data.allStrapiProduct?.edges

    /**
     * Filter by product caliber first;
     * this list should then be filterable via bullet types/application
     */
    let filteredData = data.allStrapiProduct?.edges.filter((product: any) => {
      const { calibers } = product.node

      if (calibers === null) return false
      if (state.calibers.length === 0) return true

      const fullCalibers = calibers.map((cal: any) => cal.Name)

      return state.calibers.some((r: any) => fullCalibers.includes(r))
    })

    /**
     * Now let's add additional layer of filtering for bullet type
     */
    filteredData = filteredData.filter((product: any) => {
      const { bullet_type } = product.node

      if (bullet_type === null) return false
      if (state.bullet_type.length === 0) return true

      return state.bullet_type.includes(bullet_type.name)
    })

    /**
     * And so on... for application
     */
    filteredData = filteredData.filter((product: any) => {
      const { applications } = product.node

      if (applications === null) return false
      if (state.application.length === 0) return true

      const fullApplications = applications.map((app: any) => app.name)

      return state.application.some((r: any) => fullApplications.includes(r))
    })

    filteredData = filteredData.filter((product: any) => {
      const { weight } = product.node

      if (weight === null) return false
      if (state.weight.length === 0) return true
      return state.weight.includes(weight.name)
    })

    return filteredData
  }, [state])

  return (
    <Layout
      seo={{ title: "Ammunition", description: "Ammunition Parent Page" }}
      location={props.location}
    >
      <CategoryHero
        title="All Ammunition"
        text="At Aguila, only the best materials enter our factory and only the best products leave it. We have a careful and extensive process of sourcing raw materials to ensure everything we create provides unparalleled performance."
        bulletImage={data?.bulletImage?.childImageSharp?.gatsbyImageData}
        bulletImageAlt="rifle shell"
        hasLocatorCTA
      />

      {/* <AmmoFilter
        currentFilters={state.allFilters}
        dispatch={dispatch}
        filters={NewFilterData}
        viewButtonToggle={displayList}
        viewButtonClickOnClick={handleDisplayChange}
      />

      {filteredProducts.length === 0 && (
        <Box as="section">
          <Container sx={{ variant: "layout.wide" }}>
            <Box sx={{ overflowX: "auto", width: "100%" }}>
              <Heading
                as="h4"
                variant="subtitle"
                sx={{ textAlign: "center", mb: 12 }}
              >
                Whoops, looks like we don't have a product that matches this
                description right now. To explore what we do have in our
                inventory, try removing certain filters.
              </Heading>
            </Box>
          </Container>
        </Box>
      )} */}

      {displayList && filteredProducts.length !== 0 && (
        <Box as="section">
          <Container sx={{ variant: "layout.wide", mb: 12 }}>
            <Box sx={{ overflowX: "auto", width: "100%" }}>
              <Themed.table
                sx={{
                  width: "964px",
                  "& td, & th": {
                    width: "25%",
                  },
                  "& td:nth-of-type(1), & th:nth-of-type(1)": {
                    width: "28%",
                  },
                  "& td:nth-of-type(3), & th:nth-of-type(3)": {
                    width: "13%",
                  },
                }}
              >
                <thead sx={{ "& th": { pb: 8 } }}>
                  <tr>
                    <Heading as="th" variant="productTableHeading">
                      Caliber/Gauge
                    </Heading>
                    <Heading as="th" variant="productTableHeading">
                      Bullet Type
                    </Heading>
                    <Heading as="th" variant="productTableHeading">
                      Weight
                    </Heading>
                    <Heading as="th" variant="productTableHeading">
                      Application
                    </Heading>
                    {/* <Heading as="th" variant="productTableHeading">
                      Compare
                    </Heading> */}
                  </tr>
                </thead>
                <tbody
                  sx={{
                    "& td": {
                      py: 8,
                      pl: 5,
                    },
                    "& > tr:nth-of-type(2n-1)": {
                      "& > td": {
                        backgroundColor: "lightTan",
                      },
                    },
                    "& tr:hover": {
                      td: {
                        backgroundColor: "tan",
                      },
                    },
                  }}
                >
                  {filteredProducts.map((product: any, index: number) => (
                    <tr key={index}>
                      <Themed.td>
                        <AguilaButton
                          variant="textOnly"
                          url={`/products/${product?.node?.slug}`}
                          text={product?.node?.title as string}
                        />
                      </Themed.td>
                      <Themed.td>
                        {product?.node?.bullet_type === null
                          ? "-"
                          : product?.node?.bullet_type?.name}
                      </Themed.td>
                      <Themed.td>
                        {product?.node?.applications.length === 0
                          ? "-"
                          : product?.node?.applications.map(
                              (app: any, index: number) => (
                                <span key={index} sx={{ display: "block" }}>
                                  &#8226; {app.name}
                                </span>
                              )
                            )}
                      </Themed.td>
                      <Themed.td>{product.node?.weight?.name || "-"}</Themed.td>
                      {/* <Themed.td>
                        <CompareCheckbox
                          id={`checkbox-${index}`}
                          onChange={() => {}}
                          checked={false}
                          value={product?.title as string}
                          name={product?.node?.slug as string}
                        />
                      </Themed.td> */}
                    </tr>
                  ))}
                </tbody>
              </Themed.table>
            </Box>
          </Container>
        </Box>
      )}

      {!displayList && filteredProducts.length !== 0 && (
        <Box as="section">
          <Container sx={{ mb: 12 }}>
            <Flex
              sx={{
                flexWrap: "wrap",
                justifyContent: "flex-start",
                gap: 5,
                "& > article": {
                  flex: ["1 100%", "1 40%", "1 30%", null, "1 21%"],
                  maxWidth: ["100%", null, "33%", null, null],
                },
              }}
            >
              {filteredProducts.map((product: any) => (
                <ProductCard
                  isShotShell={product.node?.categories?.find(
                    (e: any) =>
                      typeof e?.name === "string" &&
                      e?.name !== null &&
                      e?.name.toLowerCase() === "shotshell"
                  )}
                  weight={product.node?.weight?.name || "-"}
                  key={product?.node?.slug}
                  image={
                    product?.node?.product_images.length > 0
                      ? product?.node?.product_images[0]?.localFile
                          ?.childImageSharp?.gatsbyImageData
                      : null
                  }
                  imageAlt={
                    product?.node?.product_images.length > 0
                      ? product?.node?.product_images[0]?.alternativeText
                      : ""
                  }
                  showImage
                  productTitle={product?.node?.title as string}
                  productUrl={`/products/${product?.node?.slug}`}
                  bulletType={product?.node?.bullet_type?.name as string}
                  application={
                    product?.node?.applications.length === 0
                      ? "-"
                      : (product?.node?.applications
                          ?.map((app: any) => app.name)
                          .join(", ") as string)
                  }
                />
              ))}
            </Flex>
          </Container>
        </Box>
      )}
    </Layout>
  )
}

export default CategoryParent

export const query = graphql`
  query CategoryParent {
    bulletImage: file(relativePath: { eq: "rifle-2x.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    allStrapiBulletType {
      edges {
        node {
          name
          id
          products {
            id
          }
        }
      }
    }
    allStrapiApplication {
      edges {
        node {
          name
          id
        }
      }
    }
    allStrapiCaliber {
      edges {
        node {
          Name
          id
        }
      }
    }
    allStrapiProduct {
      edges {
        node {
          title
          slug
          applications {
            name
            id
          }
          categories {
            name
          }
          calibers {
            Name
            id
          }
          weight {
            name
            id
          }
          product_images {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alternativeText
          }
          bullet_type {
            name
          }
        }
      }
    }
    UniqueApplications: allStrapiProduct {
      distinct(field: applications___name)
    }
    UniqueShotSizes: allStrapiProduct {
      distinct(field: shot_size___name)
    }
    UniqueCalibers: allStrapiProduct {
      distinct(field: calibers___Name)
    }
    UniqueBulletTypes: allStrapiProduct {
      distinct(field: bullet_type___name)
    }
    UniqueWeights: allStrapiProduct {
      distinct(field: weight___name)
    }
  }
`
